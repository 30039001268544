<template>
  <v-container>
    <genericDialog
      :dialog-on="inviteDialog.dialogOn"
      :bg-image="inviteDialog.bgImage"
      :btn-text="inviteDialog.btnText"
      :dialog-text="inviteDialog.dialogText"
      :dialog-title="inviteDialog.dialogTitle"
      :toggle-dialog="toggleInviteDialogDialog"
      :btn-action="toggleInviteDialogDialog"
    />
    <v-col style="margin-left: 10px; padding-bottom: 23px">
      <BackArrow class="mt-2" :route="'root'" />
    </v-col>

    <v-form
      id="formcadastro"
      ref="formcadastro"
      @submit.prevent="salvar"
      :lazy-validation="true"
    >
      <v-container v-if="!hasCPF">
        <v-row align="center" align-content="center" justify="center">
          <v-col cols="12" class="text-left main-title">
            Informe seu cpf para localizarmos sua empresa
          </v-col>
          <v-col cols="12">
            <v-text-field
              name="cpf"
              id="cpf"
              placeholder
              type="text"
              v-model="fields.cpf"
              :rules="cpfRules"
              :validate-on-blur="false"
              v-mask="$util.formmasks.cpf"
            />
          </v-col>

          <v-col cols="12">
            <generic-btn
              style="font-weight: bold"
              class="generic-title"
              :btn-props="{
                block: true,
                large: true,
                loading: btnLoading,
                disabled: !fields.cpf,
                type: 'submit',
                form: 'formcadastro',
              }"
            >
              Localizar
            </generic-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-else-if="!hasBeneficiary">
        <v-col class="text-left main-title"> CPF não encontrado </v-col>
        <v-col cols="12" class="main-text text-left">
          Não foram encontrados dados com o CPF informado. Entre em contato com
          o suporte do sistema para mais informações.
        </v-col>
      </v-container>
      <span v-else-if="!showDependents">
        <v-container>
          <v-col cols="12" class="text-left">
            <p class="main-title">Conectar Empresa</p>
            <p class="main-text">
              Selecione a empresa que você deseja conectar e ter acesso aos seus
              benefícios
            </p>
          </v-col>
        </v-container>
        <v-flex
          xs12
          v-for="connectCompany in connectCompanies"
          :key="connectCompany.financialGroup.id"
        >
          <v-container>
            <generic-card
              style="padding: 10px"
              :title="connectCompany.financialGroup.name"
            >
              <v-row align="center" align-content="center" justify="center">
                <v-col style="padding-right: 0; padding-top: 0" cols="1">
                  <pulseIcon
                    :color="
                      connectCompany.status
                        ? getStatusColor(connectCompany.status)
                        : 'red'
                    "
                  />
                </v-col>

                <v-col cols="11">
                  <p style="margin-bottom: 10px" class="text-left status-text">
                    <b>{{
                      connectCompany.status
                        ? getStatusTraducao(connectCompany.status)
                        : "Você ainda não se conectou com esta empresa. Clique em Solicitar Conexão."
                    }}</b>
                  </p>
                </v-col>
              </v-row>

              <v-card-actions style="margin-top: 20px">
                <v-spacer />
                <generic-btn
                  :btn-props="{ rounded: true, font: 'bold' }"
                  v-if="isStatusAtivo(connectCompany.status)"
                  color="secondary"
                  :defaultTheme="false"
                  class="generic-title"
                  style="width: 140px; font-weight: bold"
                  :on-click="
                    () =>
                      disconnectFromCompany(connectCompany.connectCompany.id)
                  "
                >
                  Desconectar
                </generic-btn>
                <generic-btn
                  v-if="!connectCompany.status"
                  color="secondary"
                  class="generic-title"
                  :defaultTheme="false"
                  :btn-props="{ rounded: true, font: 'bold' }"
                  style="width: 170px; font-weight: bold"
                  :on-click="
                    () => connectWithCompany(connectCompany.financialGroup.id)
                  "
                >
                  Solicitar Conexão
                </generic-btn>
              </v-card-actions>
            </generic-card>
          </v-container>
        </v-flex>
      </span>
      <v-container class="pt-5" :style="!hasBeneficiaryTelemedicine ? 'padding-bottom: 80px;' : ''" v-if="showDependents && dependents.length > 0">
        <v-col v-if="!enableSendInvite" cols="12" class="text-left pl-1">
          <genericCard
            v-for="connectCompany in connectCompanies"
            :key="connectCompany.financialGroup.id"
          >
            <v-row align="center" align-content="center" justify="center">
              <v-col style="padding-right: 0" cols="1">
                <pulseIcon />
              </v-col>

              <v-col style="padding-left: 8px" cols="11">
                <p style="margin: 0" class="main-strong-text">
                  Você está conectado à {{ connectCompany.financialGroup.name }}
                </p>
              </v-col>
            </v-row>
          </genericCard>
        </v-col>

        <v-col>
          <p class="main-title">Conectar Dependentes</p>
          <p class="main-text">Agora você pode conectar seus dependentes</p>
        </v-col>

        <Dependentes
          :reload="loadDependents"
          :dependents="dependents"
          :enable-send-invite="enableSendInvite"
          v-on:toggle-invite="toggleInvite"
        />
      </v-container>
      <v-container v-if="showDependents && dependents.length === 0">
        <v-col cols="12" class="text-left">
          <p class="main-title">Empresa Conectada</p>
        </v-col>
        <generic-card
          :key="connectCompany.financialGroup.id"
          v-for="connectCompany in connectCompanies"
          style="padding: 10px"
          :title="connectCompany.financialGroup.name"
        >
          <v-row align="center" align-content="center" justify="center">
            <v-col style="padding-right: 0; padding-top: 0" cols="1">
              <pulseIcon
                :color="
                  connectCompany.status
                    ? getStatusColor(connectCompany.status)
                    : 'green'
                "
              />
            </v-col>
            <v-col style="padding-left: 10px" cols="11">
              <p style="margin-bottom: 10px" class="text-left status-text">
                <b>{{
                  connectCompany.status
                    ? getStatusTraducao(connectCompany.status)
                    : "Conexão Aceita"
                }}</b>
              </p>
            </v-col>
          </v-row>
          <v-row v-if="isConnectionOk">
            <v-col cols="12">
              <p class="text-left status-text">
                <b
                  >Parabéns. A sua conexão com a empresa acaba de ser
                  estabelecida.</b
                >
              </p>
            </v-col>
          </v-row>
        </generic-card>
      </v-container>
      <!-- verificar os dois cenários, logado com beneficiário e com depedente -->
      <v-container
        v-if="
          hasCompanyConnection &&
          isBeneficiary &&
          hasBeneficiaryTelemedicine
        "
      >
        <v-col cols="12">
          <v-row :class="dependentsWithTelemedicine.length ? '' : 'mb-16 pb-16'">
            <v-col
              cols="10"
              @click="
                () => (customExpanded.isEnabled = !customExpanded.isEnabled)
              "
            >
              <p class="main-title mb-0 pb-0">Telemedicina</p>
              <span class="text-caption" style="color: var(--v-primary)"
                >Controle de dependentes</span
              >
            </v-col>

            <v-col
              cols="2"
              @click="
                () => (customExpanded.isEnabled = !customExpanded.isEnabled)
              "
            >
              <v-btn small icon color="primary">
                <v-icon small>{{
                  customExpanded.isEnabled
                    ? customExpanded.iconEnabled
                    : customExpanded.iconDisabled
                }}</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" v-if="customExpanded.isEnabled" class="main-text">
              <p class="text-subtitle-2 text-justify">
                Inclua seus dependentes na telemedicina por apenas
                <span class="font-weight-bold">R$ 7,00 mensais por vida</span>.
              </p>
              <p class="text-subtitle-2 text-justify mb-0">
                Com este serviço, seu dependente terá:
              </p>
              <ul class="text-subtitle-2 mb-3">
                <li>08 consultas grátis por ano de contrato</li>
                <li>Especialidades médicas por vídeo com hora marcada</li>
                <li>Pronto atendimento por vídeo 24h</li>
                <li>Desconto medicamento</li>
              </ul>
              <p class="text-subtitle-2 text-justify">
                São elegíveis: cônjuge, filhos e pais. Para seguir, basta
                adicionar o dependente logo abaixo.
              </p>
              <p class="text-subtitle-2 text-justify mb-0">
                Veja os
                <a target="_blank" :href="urlTerms">Termos e Condições</a> a
                serem aceitos.
              </p>
            </v-col>

            <v-col cols="12" v-if="customExpanded.isEnabled">
              <generic-card
                v-if="allDependentsWithoutTelemedicine && allDependentsWithoutTelemedicine.length"
                style="padding: 5px"
                title="Adicionar Dependente"
                :click="() => redirectToTelemedicine()"
              >
                <v-row align="center" align-content="center" justify="center">
                  <v-col cols="12">
                    <p
                      style="margin-bottom: 10px; margin-left: 6px"
                      class="text-left status-text"
                    >
                      <b>Cadastrar Telemedicina</b>
                    </p>
                  </v-col>
                </v-row>
              </generic-card>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
      <v-container class="mb-16" v-if="hasCompanyConnection && dependentsWithTelemedicine.length">
        <v-col cols="12" class="text-left">
          <p class="main-title">Dependentes ativos</p>
        </v-col>
        <v-col
          cols="12"
          class="mb-2 pb-0"
          v-for="(dependent, index) in dependentsWithTelemedicine"
          :key="index"
        >
          <generic-card style="padding: 10px">
            <v-row align-content="center" align="center">
              <v-col cols="7" class="pa-0">
                <p :class="dependent.planStartDate ? 'text-h6 ma-0' : 'text-h6 ma-0 mt-4'" style="color: var(--v-primary)">
                  {{ dependent.personName }}
                </p>
              </v-col>
              <v-col cols="1">
                <generic-btn
                  v-if="dependent.planStartDate"
                  class="mb-4 mt-2"
                  color="#E9EBFD"
                  :defaultTheme="false"
                  :btn-props="{ rounded: true, elevation: 8, block: true }"
                  style="font-weight: bold; color: var(--v-error)"
                  :on-click="() => redirectToCancelDependent(dependent, index)"
                >
                  Cancelar
                </generic-btn>
              </v-col>
            </v-row>
            <v-row class="my-0" align-content="center" align="center">
              <v-col cols="12" :class="dependent.planStartDate ? 'pa-0' : 'pa-0 mt-4'">
                <p class="status-text ma-0">Dependente {{ index + 1 }}</p>
              </v-col>
            </v-row>
            <v-row align="center" align-content="center" justify="center">
              <v-col style="padding-right: 0; padding-top: 0" cols="1">
                <pulseIcon :color="dependent.planStartDate ? 'green' : 'orange'" />
              </v-col>
              <v-col style="padding-left: 10px" cols="11">
                <p style="margin-bottom: 10px" class="text-left status-text">
                  <b v-if="dependent.planStartDate">Ativo em {{ $util.vueFormatDateToBR(dependent.planStartDate) }}</b>
                  <b v-else>Pendente</b>
                </p>
              </v-col>
            </v-row>
          </generic-card>
        </v-col>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import $util from "../../common/util";
import { updateUser } from "../../common/securityserver";
import {
  findByCPFGroupingByFinancialGroup,
  requestConnectionWithCompany,
  disconnectFromCompany,
} from "../../common/euprotegido-service";
import {
  existsBeneficiaryByCpf,
} from "../../common/contract-service";
import Dependentes from "./Dependentes";
import genericCard from "./../../components/generic-card/index.vue";
import GenericBtn from "../../components/generic-btn";
import pulseIcon from "../../components/pulse-icon/index.vue";
import GenericDialog from "../../components/generic-dialog/index.vue";
import BackArrow from "../../components/covid/back-arrow/index.vue";
import UserService from "../../services/security/User";
import ContractService from "../../services/contract/ContractService";
import MedicalAppointmentEventService from "../../services/healthcare/MedicalAppointmentEvent";
import MenuService from "@/services/security/Menu";
import EuProtegidoService from "@/services/euprotegido/EuProtegidoService";

export default {
  components: {
    Dependentes,
    genericCard,
    pulseIcon,
    GenericBtn,
    GenericDialog,
    BackArrow,
  },
  props: {
    dialog: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      urlTerms: null,
      customExpanded: {
        isEnabled: true,
        iconDisabled: "fas fa-chevron-down",
        iconEnabled: "fas fa-chevron-up",
      },
      allDependents: [],
      allDependentsWithoutTelemedicine: [],
      isConnectionOk: false,
      isHolder: false,
      fields: {
        cpf: "",
      },
      describe: "",
      hasCPF: false,
      hasBeneficiary: true,
      rules: [],
      btnLoading: false,
      cpfRules: [(v) => !v || $util.validateCPF(v) || "CPF inválido"],
      connectCompanies: [],
      showDependents: "",
      enableSendInvite: false,
      dependents: [],
      inviteDialog: {
        dialogOn: false,
        bgImage: "/img/bg-dialogs/bg-dialog-convidar-dependente.png",
        dialogTitle: "Solicitação enviada",
        dialogText: "",
        btnText: "Ok",
      },
      benefitTelemedicine: "Telemedicina",
      dependentsWithTelemedicine: [],
    };
  },
  async created() {
    this.isConnectionOk = this.$route.query && (this.$route.query.receiveConnection || this.$route.query.receiveConnection === "true");
    this.fields.cpf = this.$auth.user().cpf;
    this._userService = new UserService();
    this._menuService = new MenuService();
    this._contractService = new ContractService();
    this._euProtegidoService = new EuProtegidoService();
    this.appointmentEventService = new MedicalAppointmentEventService();
    this.hasCPF = this.fields.cpf != null;
    const session = this.$auth.getSession();
    const pendingDependents = session && session.user && session.user.pendingDependents && session.user.pendingDependents.length
        ? session.user.pendingDependents : [];

    this.isHolder = session && session.user && session.user.beneficiaries;

    this.loading(true);
    if (this.hasCPF) {
      await this.loadConnectCompanies();
      await this.loadDependents();
    }

    await this.loadAllDependentsByBeneficiaryId();
    this.filterDependents(pendingDependents);
    this.loadBeneficiariesWithTelemedicine();
    await this.filterDependentContractedPlanPendings(pendingDependents);
    this.loadDependentTerms();
    this.loading(false);
  },
  computed: {
    hasBeneficiaryTelemedicine() {
      return (
        this.$auth.user() &&
        this.$auth.user().beneficiaries &&
        this.$auth.user().beneficiaries.length &&
        this.$auth
          .user()
          .beneficiaries.some(
            (contractedPlan) =>
              contractedPlan.benefitName === this.benefitTelemedicine
          )
      );
    },
    hasCompanyConnection() {
      return this.$auth.user() && this.$auth.user().hasCompanyConnection;
    },
    isBeneficiary() {
      return (
        this.$auth.user() &&
        this.$auth.user().beneficiaries &&
        this.$auth.user().beneficiaries.length
      );
    },
    showdialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        this.$emit("update:dialog", value);
      },
    },
  },
  methods: {
    ...mapMutations([
      "showmsg",
      "loading",
      "showDefaultErrorMsg",
      "refreshReloadableKey",
    ]),
    loadDependentTerms() {
      this.appointmentEventService
        .getURLFromTelemedicineDependentTerms()
        .then((result) => {
          if (result && result.data) {
            this.urlTerms = result.data;
          }
        });
    },
    async loadAllDependentsByBeneficiaryId() {
      const beneficiaryId = this.$auth.user() && this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length
          ? this.$auth.user().beneficiaries[0].beneficiaryId : null;
      if (this.isBeneficiary && this.hasCompanyConnection && beneficiaryId) {
        await this._contractService.FindAllByBeneficiaryId(beneficiaryId)
          .then((result) => {
            if (result && result.data) {
              this.allDependents = result.data;
            }
          });
      }
    },
    filterDependents(pendingDependents) {
      const sessionDependents = this.getAllDependentsByBeneficiaryContractedPlan();
      if (this.allDependents && this.allDependents.length && sessionDependents && sessionDependents.length) {
        this.allDependents.forEach((dependent) => {
          if (!sessionDependents.some((sessionDependent) => sessionDependent.dependentId === dependent.id)) {
            this.allDependentsWithoutTelemedicine.push(dependent);
          }
        });
      } else {
        this.allDependentsWithoutTelemedicine = this.allDependents;
      }

      if(pendingDependents.length) {
        pendingDependents.forEach((pendingDependent) => {
          const dependentFound = this.allDependentsWithoutTelemedicine.find(dependentWithoutTelemedicine =>
              dependentWithoutTelemedicine.physicalPerson.cpf === pendingDependent.personCPF);
          if(dependentFound) {
            const indexOf = this.allDependentsWithoutTelemedicine.indexOf(dependentFound);
            this.allDependentsWithoutTelemedicine.splice(indexOf, 1);
          }
        })
      }

    },
    getAllDependentsByBeneficiaryContractedPlan() {
      if (this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length) {
        const bcp = this.$auth.user()
          .beneficiaries.find((contractedPlan) => contractedPlan.benefitName === this.benefitTelemedicine);
        if (bcp) {
          return bcp.dependents;
        }
      }
      return null;
    },
    async filterDependentContractedPlanPendings(pendingDependents) {
      if(pendingDependents.length) {
        const params = new URLSearchParams();
        pendingDependents.forEach(dcp => {
          params.append('ids', dcp.contractedPlanId);
        });

        let dependentContractedPlans = []
        await this._contractService.FindDependentContractedPlansPendingsByIds(params)
            .then((response) => {
              dependentContractedPlans = response.data;
            });

        if(pendingDependents.length && dependentContractedPlans.length) {
          dependentContractedPlans.forEach((dcp) => {
            if(dcp.planStartDate) {

              const dcpFound = pendingDependents.find((pendingDependent) => pendingDependent.contractedPlanId === dcp.contractedPlanId);

              if(dcpFound) {
                const indexOf = pendingDependents.indexOf(dcpFound);

                if(indexOf >= 0) {
                  pendingDependents[indexOf].planStartDate = dcp.planStartDate;
                }
              }
            }
          });

          this.dependentsWithTelemedicine = this.dependentsWithTelemedicine.concat(pendingDependents);
        } else {
          this.dependentsWithTelemedicine = this.dependentsWithTelemedicine.concat(pendingDependents);
        }
      }
    },
    redirectToTelemedicine() {
      this.$router.push({
        name: "telemedicineDependentsManagement",
        params: { dependents: this.allDependentsWithoutTelemedicine },
      });
    },
    redirectToConnectCompany() {
      setTimeout(() => {
        this.$router.go(0);
      }, 5000);
    },
    closeDialog() {
      this.showdialog = false;
    },
    toggleInviteDialogDialog() {
      this.inviteDialog.dialogOn = !this.inviteDialog.dialogOn;
    },
    backPage() {
      if (this.enableSendInvite) {
        this.toggleInvite();
      } else {
        this.$router.push("/");
      }
    },
    toggleInvite() {
      this.enableSendInvite = !this.enableSendInvite;
    },
    isStatusAtivo(connectCompany) {
      return connectCompany == "ACTIVE";
    },
    getStatusTraducao(status) {
      let traducao = "";

      if (status == "ACTIVE") traducao = "Conectado";
      else if (status == "PENDING") traducao = "Pendente";
      else if (status == "INACTIVE") traducao = "Inativo";
      else if (status == "ACCEPT") traducao = "Aceito";
      else if (status == "REFUSED") traducao = "Recusado";

      return traducao;
    },
    getStatusColor(status) {
      let traducao = "";

      if (status == "ACTIVE") traducao = "green";
      else if (status == "PENDING") traducao = "yellow";
      else if (status == "INACTIVE") traducao = "red";
      else if (status == "ACCEPT") traducao = "green";
      else if (status == "REFUSED") traducao = "red";

      return traducao;
    },
    disconnectFromCompany(connectCompanyID) {
      this.loading(true);
      disconnectFromCompany(connectCompanyID)
        .then((res) => {
          this.showmsg({
            text: "Solicitação efetuada com sucesso.",
            type: "success",
          });
          this.loading(false);
        })
        .catch((err) => {
          this.showmsg({
            text: "Erro ao carregar informações.",
            type: "error",
          });
          this.loading(false);
        });
    },
    async connectWithCompany(financialGroupId) {
      this.loading(true);

      const data = {
        financialGroupId,
        cpf: this.$auth.user().cpf,
        userID: this.$auth.user().id,
      };

      await this.requestConnectionWithCompanyAndReloadSession(data, financialGroupId)
        .catch((err) => {
          this.showmsg({
            text: "Erro ao conectar com a empresa.",
            type: "error",
          });
        })
        .finally(() => {
          this.toggleInviteDialogDialog();
          this.loading(false);
        });
    },
    async requestConnectionWithCompanyAndReloadSession(data, financialGroupId){
      await requestConnectionWithCompany(data)
          .then((res) => {
            this.connectCompanies.forEach(function (c) {
              if (c.financialGroup.id === financialGroupId) {
                c.connectCompanyOrder = res.data;
                c.status = res.data.status;
              }
            });
          });
      await this.reloadSessionByToken();
      await this.reloadMenus();
    },
    async reloadSessionByToken() {
      const token = this.$auth.getAccessToken();
      let reloadSessionResponse = await this._userService.reloadSessionByToken(
        token
      );

      try {
        if (reloadSessionResponse.data) {
          this.$auth.setUser(reloadSessionResponse.data.user);
          this.$auth.setCPF(this.$util.removerPontuacaoCPF(this.fields.cpf));
          this.loadBeneficiariesWithTelemedicine();
        }
      } catch (e) {
        this.showmsg({
          text: "Erro ao recarregar dados da sessão.",
          type: "error",
        });
        this.loading(false);
      }
    },
    async reloadMenus(){
      await this._menuService.LoadMenu().
      then((response) => {
        if(response.data){
          let session = this.$auth.getSession()
          session.menu = response.data
          this.$auth.setSession(session)
        }
      }).finally(() => {
          this.loading(false)
      });
    },
    async loadConnectCompanies() {
      this.checkIfBeneficiaryExists();

      if (this.$auth.user().physicalPersonId) {
        await this._euProtegidoService.FindByPhysicalPersonIdGroupingByFinancialGroup(this.$auth.user().physicalPersonId)
          .then((res) => {
            this.loadConnections(res.data);
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao carregar informações.",
              type: "error",
            });
            this.loading(false);
          });
      } else {
        findByCPFGroupingByFinancialGroup(this.$auth.user().cpf)
          .then((res) => {
            this.loadConnections(res.data);
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.data &&
              err.response.data.status &&
              err.response.data.status === 500 &&
              err.response.data.message.includes(
                "A pessoa física está vinculada à registros de usuários distintos"
              )
            ) {
              this.showmsg({
                text: "Este CPF encontra-se conectado. Por favor, informe outro CPF.",
                type: "error",
              });
              this.$auth.setCPF(null);
              this.redirectToConnectCompany();
            } else {
              this.showmsg({
                text: "Erro ao carregar informações.",
                type: "error",
              });
            }
            this.loading(false);
          });
      }
    },
    loadConnections(connections) {
      this.connectCompanies = connections;
      var connectCompanyAtivo = "";
      if (!this.connectCompanies) {
        return;
      }
      this.connectCompanies.forEach(function (connectCompany) {
        connectCompanyAtivo =
          connectCompany.connectCompanyConnection &&
          connectCompany.connectCompanyConnection.status == "ACTIVE";

        connectCompany.status = connectCompany.connectCompanyConnection
          ? connectCompany.connectCompanyConnection.status
          : connectCompany.connectCompanyRequest
          ? connectCompany.connectCompanyRequest.status
          : null;
        connectCompany.benefits = [];
        connectCompany.contracts.forEach(function (c) {
          var benefit = c.policy + " - " + c.benefit;
          benefit += c.carrier ? "/" + c.carrier : "";
          connectCompany.benefits.push(benefit);
        });
      });
      this.showDependents = connectCompanyAtivo;

      //remove multiples benefits
      if (this.connectCompanies && this.connectCompanies.length > 1) {
        this.connectCompanies.splice(1, this.connectCompanies.length - 1);
      }
    },
    checkIfBeneficiaryExists() {
      existsBeneficiaryByCpf(this.$auth.user().cpf)
        .then((res) => {
          this.hasBeneficiary = res.data == true;
        })
        .catch((err) => {
          this.showmsg({
            text: "Erro ao carregar informações.",
            type: "error",
          });
        });
    },
    async loadDependents() {
      await this._contractService.FindDependentsByBeneficiaryCpf(this.$auth.user().cpf)
        .then((res) => {
          console.log('loaddependets', res)
          this.dependents = res.data;
        })
        .catch((err) => {
          this.showmsg({
            text: "Erro ao carregar informações.",
            type: "error",
          });
          this.loading(false);
        });
    },
    async salvar() {
      if (!this.$refs.formcadastro.validate()) {
        setTimeout(this.$refs.formcadastro.resetValidation, 3000);
        return;
      }

      this.loading(true);

      const cpf = $util.removerPontuacaoCPF(this.fields.cpf);
      let existsUserEuProtegido = false;

      await this._userService
        .findUserEuProtegidoByCpf(cpf)
        .then((response) => {
          if (response && response.data) {
            this.loading(false);
            this.showmsg({
              text: "Este CPF encontra-se conectado. Por favor, informe outro CPF.",
              type: "error",
            });
            this.$auth.setCPF(null);
            existsUserEuProtegido = true;
          }
        })
        .catch(() => {
          this.loading(false);
          this.$auth.setCPF(null);
          existsUserEuProtegido = true;
          this.showmsg({ text: "Erro ao pesquisar usuário.", type: "error" });
        });

      if (!existsUserEuProtegido) {
        const user = {
          id: this.$auth.user().id,
          cpf,
        };

        this.loading(true)
        updateUser(user)
          .then(() => {
            this.$auth.setCPF(cpf);
            this.hasCPF = true;
            this.loadConnectCompanies();
            this.showmsg({ text: "Cadastro salvo.", type: "success" });
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao atualizar cadastro.",
              type: "error",
            });
            this.loading(false);
          });
      }
    },
    loadBeneficiariesWithTelemedicine() {
      if(this.$auth.user().beneficiaries){
        const bcpTelemedicine = this.$auth.user().beneficiaries.find((beneficiary) => beneficiary.benefitName === "Telemedicina");
        if(bcpTelemedicine && bcpTelemedicine.dependents) {
          this.dependentsWithTelemedicine = bcpTelemedicine.dependents;
        }
      }
    },
    redirectToCancelDependent(dependent, index) {
      this.$router.push({
        name: "cancelTelemedicine",

        params: { title: index, dependent: dependent },
      });
    },
  },
};
</script>

<style scoped>
a {
  font-weight: bold;
  text-decoration: none;
  color: var(--v-primary) !important;
}
</style>
