<template>
  <span>
    <genericDialog
      :dialog-on="inviteDialog.dialogOn"
      :bg-image="inviteDialog.bgImage"
      :btn-text="inviteDialog.btnText"
      :dialog-text="inviteDialog.dialogText"
      :dialog-title="inviteDialog.dialogTitle"
      :toggle-dialog="toggleInviteDialogDialog"
      :btn-action="toggleInviteDialogDialog"
    />
    <v-form
      v-if="enableSendInvite"
      id="forminvite"
      ref="forminvite"
      @submit.prevent="sendInvite"
      :lazy-validation="true"
    >
      <v-container style="padding: 20px">
        <v-row align="start" align-content="center" justify="start">
          <p class="main-title">Informe um e-mail para que o convite seja enviado</p>
          <v-col cols="12">
            <v-text-field
              name="email"
              id="email"
              label="E-mail"
              placeholder
              type="text"
              v-model="dependantInvite.email"
              :error-messages="emailErrorValidation"
              :validate-on-blur="false"
            />
          </v-col>
        </v-row>

        <v-row align="end" style="margin-top: 50%" align-content="center">
          <v-col cols="12">
            <generic-btn :btn-props="{ block: true }" block color="primary" type="submit" form="forminvite">
              Enviar
            </generic-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-flex v-if="!enableSendInvite" xs12>
      <v-flex v-for="dependent in dependents" :key="dependents.id">
        <v-container class="pl-0 pr-0">
          <generic-card style="padding: 10px" :title="dependent.physicalPerson.name">
            <v-col style="padding: 0;" cols="6">
              <v-chip
                style="background: #CCE9F9; color: #5A8BB0;"
                x-small
              >
                {{ dependent.physicalPerson.cpf ? $util.formatCPF(dependent.physicalPerson.cpf) : null }}
              </v-chip>
            </v-col>

            <v-card-actions style="margin-top: 20px">
              <v-row v-if="dependent.connectCompanyInvitation" align="center" align-content="center" justify="center" v-else>
                <v-col class="pr-0" style="padding: 0;" cols="2">
                  <pulseIcon :color="getStatusColor(dependent)" />
                </v-col>

                <v-col class="pl-0" style="padding: 0" cols="10">
                  <p class="mt-4 text-left status-text">
                    {{ getStatusTraducao(dependent) }}
                  </p>
                </v-col>
              </v-row>
              <v-row align="center" align-content="center" justify="center" v-else>
                <v-col style="padding: 0;" cols="2">
                  <pulseIcon color="red" />
                </v-col>

                <v-col style="padding: 0" cols="10">
                  <p class="mt-4 text-left status-text">
                    Não conectado
                  </p>
                </v-col>
              </v-row>
              <v-spacer />
              <generic-btn
                :btn-props="{ rounded: true, elevation: 0 }"
                v-if="disconnect(dependent.connectCompanyInvitation)"
                color="#E9EBFD"
                :defaultTheme="false"
                :on-click="disconnectFromCompany(dependent.connectCompanyInvitation.id)"
                style="width: 130px; font-size: 12px; font-weight: bold; color: var(--v-primary)"
              >
                Desconectar
              </generic-btn>
              <generic-btn
                v-if="inviteBtn(dependent.connectCompanyInvitation)"
                color="#E9EBFD"
                :defaultTheme="false"
                :btn-props="{ rounded: true, elevation: 0 }"
                style="width: 130px; font-size: 12px; font-weight: bold; color: var(--v-primary)"
                :on-click="() => openInvite(dependent.physicalPerson.id, dependent.physicalPerson.name, dependent.financialGroup.id)"
              >
                Enviar Convite
              </generic-btn>
            </v-card-actions>
          </generic-card>
        </v-container>
      </v-flex>
    </v-flex>
  </span>
</template>
<script>
  import { invitationToConnectionWithCompany, disconnectFromCompany } from "../../common/euprotegido-service"
  import { mapMutations } from "vuex";
  import genericCard from "./../../components/generic-card/index.vue";
  import GenericBtn from "../../components/generic-btn";
  import genericDialog from "../../components/generic-dialog/index.vue";
  import pulseIcon from "../../components/pulse-icon/index.vue";

  export default {
    data() {
      return {
        showdialog : false,
        dependantInvite : {},
        emailErrorValidation: [],
        inviteDialog: {
          dialogOn: false,
          bgImage: '/img/bg-dialogs/bg-dialog-convidar-dependente.png',
          dialogTitle: 'Solicitação enviada',
          dialogText: '',
          btnText: 'Ok',
        },
      };
    },
    props: {
      enableSendInvite: {
        required: true,
        type: Boolean
      },
      dependents: {
        required: true,
        type: Array
      },
      reload: {
        required: true,
        type: Function
      }
    },
    components: {
      GenericBtn,
      genericCard,
      genericDialog,
      pulseIcon
    },
    methods: {
      ...mapMutations(["showmsg", "loading"]),
      inviteBtn(invite) {
        if(invite) {
          if(invite.connectCompanyConnection && invite.connectCompanyConnection.status) {
            if(invite.connectCompanyConnection.status === 'DISCONNECT') {
              return true
            }
          }else {
            return invite.status === 'INACTIVE' ||
              invite.status === 'REFUSED'
          }
        }else {
          return true
        }
      },
      disconnect(invite) {
        if(invite && invite.connectCompanyConnection && invite.connectCompanyConnection.status) {
          if(invite.connectCompanyConnection.status === 'DISCONNECT') {
            return false
          }
        }else {
          return invite &&
            (invite.status === 'ACCEPT' ||
              invite.status === 'ACTIVE')
        }
      },
      disconnectFromCompany(connectCompanyID){
        this.loading(true);
        disconnectFromCompany(connectCompanyID).then(res => {
          this.showmsg({ text: "Solicitação efetuada com sucesso!", type: "success" });
          this.loading(false);
        }).catch(err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
          this.loading(false);
        })
      },
      closeDialog() {
        this.showdialog = false;
      },
      toggleInviteDialogDialog() {
        this.inviteDialog.dialogOn = !this.inviteDialog.dialogOn;
      },
      openInvite(id, name, financialGroupID) {
        this.dependantInvite.id = id;
        this.dependantInvite.name = name;
        this.dependantInvite.financialGroupID = financialGroupID;

        this.$emit('toggle-invite');
      },
      getStatusTraducao({ connectCompanyInvitation }){
        let traducao = "";

        if(connectCompanyInvitation.connectCompanyConnection && connectCompanyInvitation.connectCompanyConnection.status) {
          if (connectCompanyInvitation.connectCompanyConnection.status === 'ACTIVE')
            traducao = 'Ativo';
          else if (connectCompanyInvitation.connectCompanyConnection.status === 'DISCONNECT')
            traducao = 'Desconectado';
        }else {
          if (connectCompanyInvitation.status === 'ACTIVE')
            traducao = 'Ativo';
          else if (connectCompanyInvitation.status === 'PENDING')
            traducao = 'Pendente';
          else if (connectCompanyInvitation.status === 'INACTIVE')
            traducao = 'Inativo';
          else if (connectCompanyInvitation.status === 'REFUSED')
            traducao = 'Recusado';
          else if (connectCompanyInvitation.status === 'ACCEPT')
            traducao = 'Aceito'
        }

        return traducao;
      },
      getStatusColor({ connectCompanyInvitation }){
        let traducao = "";

        if(connectCompanyInvitation.connectCompanyConnection && connectCompanyInvitation.connectCompanyConnection.status) {
          if (connectCompanyInvitation.connectCompanyConnection.status === 'ACTIVE')
            traducao = 'green';
          else if (connectCompanyInvitation.connectCompanyConnection.status === 'DISCONNECT')
            traducao = 'red';
        }else {
          if(connectCompanyInvitation.status === 'ACTIVE')
            traducao = 'green';
          else if(connectCompanyInvitation.status === 'PENDING')
            traducao = 'yellow';
          else if(connectCompanyInvitation.status === 'INACTIVE')
            traducao = 'red';
          else if(connectCompanyInvitation.status === 'ACCEPT')
            traducao = 'green';
          else if(connectCompanyInvitation.status === 'REFUSED')
            traducao = 'red';
        }

        return traducao;
      },
      sendInvite(){
        if(!this.dependantInvite.email){
          this.emailErrorValidation = 'E-mail é obrigatório';
          return;
        }

        if(!(/.+@.+\..+/.test(this.dependantInvite.email))){
          this.emailErrorValidation = 'E-mail inválido';
          return;
        }

        var invitation = {
          userToInviteEmail : this.dependantInvite.email,
          userWhoSendInvitationID : this.$auth.user().id,
          financialGroupId : this.dependantInvite.financialGroupID ,
          physicalPerson : {
            id : this.dependantInvite.id,
            linkToInstallApp : window.location.origin + '/install',
            linkToAccessApp : window.location.origin + '/login',
          },
          appCode: process.env.VUE_APP_ACCOUNT_APP_CODE,
        }

        this.loading(true);

        invitationToConnectionWithCompany(invitation).then(res => {
          this.dependents = res.data;
          this.toggleInviteDialogDialog();
          this.$emit('toggle-invite');
          this.loading(false);
          this.$router.push("/conectarComSuaEmpresa");
        }).catch(err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
          this.loading(false);
        })
        .finally(() => { this.reload() })
      }
    }
  }
</script>
