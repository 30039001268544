<template>
  <v-dialog v-model="dialogOn" width="90%" transition="dialog-bottom-transition">
    <v-container
      class="bg-dialog"
      :style="{ backgroundImage: `url(${ bgImage })` }"
    >
      <v-layout
        class="bg-effect"
        align-end
        justify-center
      >
        <v-btn @click="toggleDialog()" class="close-btn" icon>
          <v-icon color="white">fas fa-times</v-icon>
        </v-btn>
        <v-card class="card-text">
          <p class="generic-title">{{ dialogTitle }}</p>
          <p class="generic-text mb-10" v-if="dialogText">{{ dialogText }}</p>

          <generic-btn
            :btn-props="{ block: true, large: true }"
            :on-click="btnAction"
          >
            {{ btnText }}
          </generic-btn>
        </v-card>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'GenericDialog',
  props: {
    dialogOn: {
      required: true,
      type: Boolean
    },
    bgImage: {
      required: true,
      type: String
    },
    dialogTitle: {
      required: true,
      type: String
    },
    dialogText: {
      required: false,
      type: String,
    },
    btnText: {
      default: 'Ok',
      type: String
    },
    toggleDialog: {
      required: true,
      type: Function
    },
    btnAction: {
      required: true,
      type: Function
    }
  },
}
</script>

<style scoped>
  .card-text {
    backdrop-filter: blur(10px);
    border-radius: 40px;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 2%;
    background: rgba(255, 255, 255, .5);
    width: 100%;
    min-height: 320px;
  }

  .bg-dialog {
    height: 85vh;
    width: 90vw;
    background-size: cover;
    background-position: top center;
    border-radius: 40px;
    padding: 0;
  }

  .bg-effect {
    background: rgba(66, 81, 158, 0.2);
    height: 100%;
    width: 100%;
  }

  .close-btn {
    top: 10%;
    left: 10%;
    position: absolute;
  }
</style>